'use client';

import React, { useEffect, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import Heading from '../../components/shared/Heading/Heading';
import PhoneNumberInput from '../../components/shared/PhoneNumberInput';
import { useLocation } from 'react-router-dom';
import { createOrderId } from '../../utils/orderId';
import pagadito from '../../assets/images/pagadito-sello.png';
import { createOrderInAirtable, createUserInAirtable, deleteRecordFromAirtable, processOrderCleanup } from '../../services/airtableService';

const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const { product, selectedSize, selectedColor, quantity } = location.state || {};
  
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [tabActive, setTabActive] = useState<'ContactInfo' | 'ShippingAddress' | 'PaymentMethod'>(
    'ContactInfo'
  );
  const [isShaking, setIsShaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [contactInfo, setContactInfo] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const [shippingAddress, setShippingAddress] = useState({
    city: 'Managua',
    address: '',
    addressType: 'Home',
  });

  const [errors, setErrors] = useState({
    contactInfo: {
      name: '',
      phone: '',
      email: '',
    },
    shippingAddress: {
      address: '',
    },
  });

  const validateForm = () => {
    const newErrors = {
      contactInfo: {
        name: '',
        phone: '',
        email: '',
      },
      shippingAddress: {
        address: '',
      },
    };
  
    if (!contactInfo.name.trim()) {
      newErrors.contactInfo.name = 'El nombre es obligatorio.';
    } else if (contactInfo.name.trim().length < 5) {
      newErrors.contactInfo.name = 'El nombre debe tener al menos 5 caracteres.';
    }

    if (!contactInfo.phone.trim()) {
      newErrors.contactInfo.phone = 'El teléfono es obligatorio.';
    } else if  (!/^(\+505|505)\d{8}$/.test(contactInfo.phone)) {
      newErrors.contactInfo.phone =
        'Por favor, ingrese un número de teléfono válido con código de Nicaragua (+505).';
    }    
  
    if (!contactInfo.email.trim()) {
      newErrors.contactInfo.email = 'El correo es obligatorio.';
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(contactInfo.email)) {
      newErrors.contactInfo.email = 'Por favor, ingrese un correo electrónico válido.';
    }
  
    if (!shippingAddress.address.trim()) {
      newErrors.shippingAddress.address = 'La dirección es obligatoria.';
    } else if (shippingAddress.address.trim().length < 8) {
      newErrors.shippingAddress.address =
        'La dirección debe tener al menos 8 caracteres.';
    }
  
    setErrors(newErrors);
  
    return (
      !newErrors.contactInfo.name &&
      !newErrors.contactInfo.phone &&
      !newErrors.contactInfo.email &&
      !newErrors.shippingAddress.address
    );
  };
  

  const handleConfirmOrder = async () => {
    if (!validateForm()) {
      setAlertMessage('Por favor complete todos los campos obligatorios.');
      return;
    }
  
    if (!acceptTerms) {
      setAlertMessage('Debe aceptar los términos y condiciones antes de confirmar el pedido.');
      return;
    }

    processOrderCleanup();
    setAlertMessage(null);
    setIsLoading(true);
  
    try {
      const ern = createOrderId();
      const productUrl = `${window.location.origin}/product/${product.id}`;
  
      const details = [
        {
          quantity: quantity || 1,
          description: `${product.name}${selectedSize ? ` - Talla: ${selectedSize}` : ''}${
            selectedColor ? ` - Color: ${selectedColor}` : ''
          }`,
          price: product.isOffert && product.discountPrice > 0 ? product.discountPrice : product.price,
          url_product: productUrl,
        },
        {
          quantity: 1,
          description: 'Costo de envío',
          price: deliveryCost,
          url_product: '',
        },
      ];
  
      const amount = details.reduce((acc, item) => acc + item.price * item.quantity, 0);
      let customerId;
      let order;

      try {
        customerId = await createUserInAirtable({
          fullName: contactInfo.name,
          phone: contactInfo.phone,
          email: contactInfo.email,
          shippingAddress: shippingAddress.address,
        });
      
        if (!customerId) {
          throw new Error('Fallo al guardar datos del cliente.');
        }
      
        try {

          const orderId = ern;
          order = await createOrderInAirtable({
            orderId,
            customerId,
            productId: product.id,
            size: selectedSize,
            color: selectedColor,
            quantity: quantity || 1,
            subtotal:
              (product.discountPrice && product.isOffert
                ? product.discountPrice
                : product.price) * quantity,
            shipping: deliveryCost,
            total: amount,
            orderStatus: 'Orden pendiente',
            pagaditoStatus: 'SENT',
          });
      
          if (!order) {
            throw new Error('Fallo al crear la orden.');
          }
        } catch (orderError) {
          try {
            await deleteRecordFromAirtable('Users', customerId);
          } catch (rollbackError) {
            throw new Error(`Error al eliminar el registro durante el rollback: ${rollbackError}`);
          }
          throw new Error(`Fallo al crear la orden. Usuario eliminado: ${customerId}`);
        }
      } catch (userError) {
        throw new Error('Operación abortada debido a un error en la inserción.');
      }
      
      if (customerId && order) {
  
        // Obtener el token de conexión de Pagadito
        const tokenResponse = await fetch('/api/initiate-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ operation: 'connect' }),
        });
    
        if (!tokenResponse.ok) {
          throw new Error('Error al obtener el token de Pagadito.');
        }
    
        const { data } = await tokenResponse.json();
        const token = data.value;
    
        if (!token) {
          throw new Error('No se pudo obtener el token de Pagadito.');
        }
    
        const execResponse = await fetch('/api/exec-transaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            ern,
            amount,
            details,
            currency: 'NIO',
            format_return: 'json',
          }),
        });
    
        if (!execResponse.ok) {
          throw new Error('Error al procesar la transacción con Pagadito.');
        }
    
        const { paymentUrl } = await execResponse.json();
    
        setIsRedirecting(true);
        window.location.href = paymentUrl;
      }
    } catch (error) {
      console.error('Error al procesar el pedido:', error);
      setAlertMessage('Ocurrió un error al procesar el pedido. Por favor, intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const deliveryCost = 150;
  const total = product ? (product.discountPrice && product.isOffert ? product.discountPrice :product.price) * quantity + deliveryCost : 0;
  
  const renderOrderSummary = () => (
    <div className="w-full lg:w-[36%]">
      <h3 className="text-lg font-semibold">Resumen del pedido</h3>
      <div className="mt-4 border rounded-lg p-4 bg-gray-50">
        {product ? (
          <>
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <img
                src={product.image[0].url}
                alt={product.name}
                className="w-44 h-44 object-cover rounded-lg mx-auto sm:mx-0"
              />
              <div>
                <h4 className="text-md font-medium">{product.name}</h4>

                {/* Etiquetas de Promoción y Hecho a Mano */}
                <div className="flex flex-wrap gap-2 my-2">
                  {product.isOffert && Number(product.discountPrice) > 0 && (
                    <div className="h-auto px-3 py-1 bg-[#EB773C] text-white text-sm font-semibold rounded shadow text-center">
                      Promoción
                    </div>
                  )}
                  {product.handcraft && (
                    <div className="h-auto px-3 py-1 bg-[#EB773C] text-white text-sm font-semibold rounded shadow text-center">
                      Hecho a mano
                    </div>
                  )}
                </div>

                {/* Detalles del Producto */}
                {selectedSize && <p className="text-gray-500">Talla: {selectedSize}</p>}
                {selectedColor && <p className="text-gray-500">Color: {selectedColor}</p>}
                <p className="text-gray-500">Cantidad: {quantity || 1}</p>

                {product.isOffert && Number(product.discountPrice) > 0 ? (
                  <div>
                    <span className="text-gray-500">Precio: </span>
                    <span className="font-bold text-[#EB773C]">
                      C${Number(product.discountPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                    <span className="text-gray-500 line-through ml-1">
                      C${Number(product.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </div>
                ) : (
                  <span className="text-gray-500">Precio: C${Number(product.discountPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                )}
              </div>
            </div>


            <div className="mt-8 border-t border-gray-300 pt-4">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span className="font-medium">
                  C${((product.discountPrice && product.isOffert ? product.discountPrice : product.price) * quantity)
                    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
              </div>
              <div className="flex justify-between mt-2">
                <span>Envío estimado</span>
                <span className="font-medium">
                  C${deliveryCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
              </div>
              <div className="flex justify-between mt-4 text-lg font-semibold">
                <span>Total</span>
                <span>
                  C${total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
              </div>
            </div>

            <div className="mt-3">
              <label className="flex items-center gap-2 text-sm text-gray-700">
                Nota: La comisión de Pagadito por transacción incluye un 5%, que corresponde a costos de procesamiento de tarjetas, y un cargo adicional de $0.25 USD por gestión del servicio. Estos montos ya incluyen impuestos.
              </label>              
              <label className="flex items-center gap-2 text-sm text-gray-700 mt-2">
                <input
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                  className="w-4 h-4"
                />
                Acepto los{' '}
                <a href="/terminos" className="text-[#EB773C] underline">
                  Términos y Condiciones
                </a>
              </label>
            </div>

            {alertMessage && (
              <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
                  role="alert"
                  >
                  <span className="block sm:inline">{alertMessage}</span>
                  <button
                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                    onClick={closeAlert}
                    >
                    <svg
                        className="fill-current h-6 w-6 text-red-500"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        >
                        <title>Cerrar</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                    </svg>
                  </button>
              </div>
            )}

            {isLoading && (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4"
                role="alert">
                <span className="block sm:inline">Será redirigido a Pagadito en breve.</span>
            </div>
            )}
            
            <button
              onClick={handleConfirmOrder}
              disabled={!acceptTerms || isLoading || isRedirecting}
              className={`mt-4 w-full py-3 rounded-lg text-white font-semibold transition flex items-center justify-center ${
                acceptTerms && !isLoading && !isRedirecting
                  ? 'bg-[#EB773C] hover:bg-[#d46834]'
                  : 'bg-gray-300 cursor-not-allowed'
              } ${isShaking ? 'animate-shake' : ''}`}
              onAnimationEnd={() => setIsShaking(false)}
            >
              {isLoading ? (
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-white border-opacity-50"></div>
              ) : (
                'Confirmar pedido'
              )}
            </button>
            
            <div className="mt-3 flex justify-center">
              <img
                src={pagadito}
                alt="Sello Comercio Certificado Pagadito"
                className="w-32 h-auto"
              />
            </div>
          </>
        ) : (
          <p className="text-gray-500">No se encontró información del producto.</p>
        )}
      </div>
    </div>
  );
  
  return (
    <div className="container mx-auto  pb-8 px-8 md:px-0">
      <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl mb-8">
          <Heading className='text-[#EB773C]' isMain>
            Checkout
          </Heading>
      </h2>
      <div className="flex flex-col lg:flex-row">
          <div className="flex-1 space-y-6">
            <div className="border rounded-lg p-4 shadow-sm bg-slate-50">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-[#EB773C]">Información de contacto</h3>
                  <button
                      onClick={() =>
                      setTabActive('ContactInfo')}
                      className="text-[#EB773C] flex items-center gap-2"
                      >
                      <AiOutlineEdit />
                      Editar
                  </button>
                </div>
                {tabActive === 'ContactInfo' && (
                <div className="mt-4 space-y-4">
                  <div>
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-2">
                      Nombre completo
                    </label>
                    <input
                      id="fullName"
                      type="text"
                      placeholder="Nombre completo"
                      className="w-full border rounded-lg p-2 focus:ring-2 focus:ring-orange-400 focus:outline-none"
                      value={contactInfo.name}
                      onChange={(e) => setContactInfo({ ...contactInfo, name: e.target.value })}
                    />
                    {errors.contactInfo.name && <p className="text-red-500 text-sm">{errors.contactInfo.name}</p>}
                  </div>

                  <div>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-2">
                      Número de teléfono
                    </label>
                    <PhoneNumberInput
                      value={contactInfo.phone}
                      onChange={(phone) => setContactInfo({ ...contactInfo, phone })}
                      error={!!errors.contactInfo.phone}
                      errorText={errors.contactInfo.phone}
                      defaultCountry="ni"
                    />
                  </div>

                  <div>
                    <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700 mb-2">
                      Correo electrónico
                    </label>
                    <input
                      id="emailAddress"
                      type="email"
                      placeholder="Correo electrónico"
                      className="w-full border rounded-lg p-2 focus:ring-2 focus:ring-orange-400 focus:outline-none"
                      value={contactInfo.email}
                      onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                    />
                    {errors.contactInfo.email && <p className="text-red-500 text-sm">{errors.contactInfo.email}</p>}
                  </div>
                </div>
                )}
            </div>
            <div className="border rounded-lg p-4 shadow-sm bg-slate-50">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-[#EB773C]">Dirección de envío</h3>
                  <button
                      onClick={() =>
                      setTabActive('ShippingAddress')}
                      className="text-[#EB773C] flex items-center gap-2"
                      >
                      <AiOutlineEdit />
                      Editar
                  </button>
                </div>
                {tabActive === 'ShippingAddress' && (
                <div className="mt-4 space-y-4">
                  <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-2">
                      Ciudad
                    </label>
                    <input
                      id="city"
                      type="text"
                      placeholder="Ciudad"
                      className="w-full border rounded-lg p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400 focus:outline-none"
                      value={shippingAddress.city}
                      readOnly
                    />
                  </div>

                  <div>
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-2">
                      Dirección
                    </label>
                    <textarea
                      id="address"
                      placeholder="Dirección"
                      className="w-full border rounded-lg p-2 focus:ring-2 focus:ring-orange-400 focus:outline-none"
                      value={shippingAddress.address}
                      onChange={(e) =>
                        setShippingAddress({ ...shippingAddress, address: e.target.value })
                      }
                      rows={3}
                      style={{
                        minHeight: '100px',
                        maxHeight: '200px',
                      }}
                    />
                    {errors.shippingAddress.address && (
                      <p className="text-red-500 text-sm">{errors.shippingAddress.address}</p>
                    )}
                  </div>

                
                  <div>
                    <span className="block text-sm font-medium text-gray-700">Tipo de Dirección</span>
                    <div className="flex items-center gap-4 mt-2">
                      <label htmlFor="addressTypeHome" className="flex items-center gap-2">
                        <input
                          id="addressTypeHome"
                          type="radio"
                          name="addressType"
                          value="Home"
                          checked={shippingAddress.addressType === 'Home'}
                          onChange={() =>
                            setShippingAddress({ ...shippingAddress, addressType: 'Home' })
                          }
                        />
                        Hogar
                      </label>
                      <label htmlFor="addressTypeOffice" className="flex items-center gap-2">
                        <input
                          id="addressTypeOffice"
                          type="radio"
                          name="addressType"
                          value="Office"
                          checked={shippingAddress.addressType === 'Office'}
                          onChange={() =>
                            setShippingAddress({ ...shippingAddress, addressType: 'Office' })
                          }
                        />
                        Oficina
                      </label>
                    </div>
                  </div>
                </div>              
                )}
            </div>
            <div className="border rounded-lg p-4 shadow-sm bg-slate-50">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium text-[#EB773C]">Método de pago</h3>
                <button
                  onClick={() => setTabActive('PaymentMethod')}
                  className="text-[#EB773C] flex items-center gap-2"
                >
                  <AiOutlineEdit />
                  Editar
                </button>
              </div>
              {tabActive === 'PaymentMethod' && (
                <div className="mt-4 space-y-4" onMouseEnter={() => setIsShaking(true)}>
                  <p className="text-gray-700">
                    El pago se realizará mediante la plataforma Pagadito, una solución segura para procesar
                    pagos en línea. Este paso se hará al presionar el botón que confirma el pedido.
                  </p>
                </div>
              )}
            </div>

          </div>
          <div className="my-10 shrink-0 border-t border-neutral-300 lg:mx-10 lg:my-0 lg:border-l lg:border-t-0 xl:mx-14 2xl:mx-16"></div>
          {renderOrderSummary()}
      </div>
    </div>
  );
};

export default Checkout;
