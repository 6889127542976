import axios from "axios";
import { createUserId } from "../utils/userId";

const AIRTABLE_BASE_URL = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}`;
const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

export interface AirtableRecord {
  id: string;
  fields: {
    Name: string;
    Description: string;
    Price: string;
    DiscountPrice: string;
    Image: Array<{ url: string }>;
    Category: string;
    Stock: string;
    Color: string[];
    Size: string[];
    IsOffert: boolean;
    Handcraft: boolean;
  };
}

export interface CategoryRecord {
  id: string;
  fields: {
    Name: string;
    Description: string;
  };
}

/**
 * Función para obtener registros de una tabla en Airtable.
 * @param table - Nombre de la tabla en Airtable
 * @returns Promise<AirtableRecord[]>
 */
export const fetchRecords = async (table: string): Promise<AirtableRecord[]> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/${table}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    if (!response.data.records || !Array.isArray(response.data.records)) {
      throw new Error("La respuesta de Airtable no contiene registros válidos.");
    }

    return response.data.records.map((record: any) => ({
      id: record.id,
      fields: {
        Name: record.fields.Name || "",
        Description: record.fields.Description || "",
        Price: record.fields.Price || "0",
        DiscountPrice: record.fields.DiscountPrice || "0",
        Image: Array.isArray(record.fields.Image) ? record.fields.Image : [],
        Category: record.fields.Category || "",
        Stock: record.fields.Stock || "0",
        Color: Array.isArray(record.fields.Color) ? record.fields.Color : [],
        Size: Array.isArray(record.fields.Size) ? record.fields.Size : [],
        IsOffert: record.fields.IsOffert || false,
        Handcraft: record.fields.Handcraft || false,
      },
    }));
  } catch (error) {
    console.error("Error al obtener datos de Airtable:", error);
    throw error;
  }
};

/**
 * Función para obtener el nombre de una categoría dado su ID.
 * @param categoryId - ID de la categoría
 * @returns Promise<{ id: string; name: string }>
 */
export const resolveCategory = async (categoryId: string): Promise<{ id: string; name: string }> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Categories/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    if (!response.data || !response.data.fields) {
      throw new Error(`No se encontró la categoría con ID ${categoryId}`);
    }

    return {
      id: response.data.id,
      name: response.data.fields.Name || "Sin nombre",
    };
  } catch (error) {
    console.error(`Error al resolver categoría con ID ${categoryId}:`, error);
    throw error;
  }
};

/**
 * Función para registrar un usuario en la tabla `Users` de Airtable.
 * @param user - Información del usuario a registrar (nombre completo y correo electrónico).
 * @returns Promise<void>
 */
export const registerUser = async (user: {
  fullName: string;
  email: string;
}): Promise<void> => {
  try {
    const userId = createUserId();

    await axios.post(
      `${AIRTABLE_BASE_URL}/Users`,
      {
        fields: {
          "ID": userId,
          "Full Name": user.fullName,
          "Email Address": user.email,
          "User Type": "Contact",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Error al registrar usuario en Airtable:", error);
    throw error;
  }
};

/**
 * Función para obtener un producto por su ID.
 * @param productId - ID del producto a buscar
 * @returns Promise<AirtableRecord | null>
 */
export const fetchProductById = async (productId: string): Promise<AirtableRecord | null> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Products/${productId}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    if (!response.data || !response.data.fields) {
      console.warn(`No se encontró un producto con el ID ${productId}`);
      return null;
    }

    const record: AirtableRecord = {
      id: response.data.id,
      fields: {
        Name: response.data.fields.Name || "",
        Description: response.data.fields.Description || "",
        Price: response.data.fields.Price || "0",
        DiscountPrice: response.data.fields.DiscountPrice || "0",
        Image: Array.isArray(response.data.fields.Image) ? response.data.fields.Image : [],
        Category: response.data.fields.Category || "",
        Stock: response.data.fields.Stock || "0",
        Color: Array.isArray(response.data.fields.Color) ? response.data.fields.Color : [],
        Size: Array.isArray(response.data.fields.Size) ? response.data.fields.Size : [],
        IsOffert: response.data.fields.IsOffert || false,
        Handcraft: response.data.fields.Handcraft || false,
      },
    };

    return record;
  } catch (error) {
    console.error(`Error al obtener el producto con ID ${productId}:`, error);
    return null;
  }
};

/**
 * Función para registrar un usuario en Airtable.
 * @param user - Información del usuario.
 * @returns Promise<string> - Devuelve el ID del usuario creado.
 */
export const createUserInAirtable = async (user: {
  fullName: string;
  phone: string;
  email: string;
  shippingAddress: string;
}): Promise<string> => {
  try {
    const userId = createUserId();

    const response = await axios.post(
      `${AIRTABLE_BASE_URL}/Users`,
      {
        fields: {
          ID: userId,
          'Full Name': user.fullName,
          'Phone Number': user.phone,
          'Email Address': user.email,
          'Shipping Address': user.shippingAddress,
          'User Type': 'Customer',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.id;
  } catch (error) {
    console.error('Error al registrar usuario en Airtable:', error);
    throw error;
  }
};

/**
 * Función para registrar una orden en Airtable.
 * @param order - Información de la orden.
 * @returns Promise<void>
 */
export const createOrderInAirtable = async (order: {
  orderId: string;
  customerId: string;
  productId: string;
  size: string | null;
  color: string | null;
  quantity: number;
  subtotal: number;
  shipping: number;
  total: number;
  orderStatus: string;
  pagaditoStatus: string;
}): Promise<string> => {

  if (!order.customerId || !order.productId || !order.orderId) {
    throw new Error('El id del comprador, id de producto o el iid de la orden no es válido o está vacío.');
  }

  try {
    const response = await axios.post(
      `${AIRTABLE_BASE_URL}/Orders`,
      {
        fields: {
          ID: order.orderId,
          Customer: [order.customerId],
          'Product ID': [order.productId],
          Size: order.size || '',
          Color: order.color || '',
          Quantity: order.quantity,
          Subtotal: order.subtotal,
          Shipping: order.shipping,
          Total: order.total,
          'Order Status': order.orderStatus,
          'Order Status Pagadito': order.pagaditoStatus,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data && response.data.id) {
      return response.data.id;
    }

    throw new Error('La respuesta de Airtable no contiene un ID de registro.');
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error('Error en la respuesta de Airtable:', error.response.data);
    }
    console.error('Error al registrar la orden en Airtable:', error);
    throw error;
  }
};

/**
 * Función para eliminar registros en Airtable.
 * @param table - Nombre de la tabla a la que vamos a ir a eliminar el elemento.
 * @param recordId - Id de registro.
 * @returns Promise<void>
 */
export const deleteRecordFromAirtable = async (table: string, recordId: string): Promise<void> => {
  try {
    await axios.delete(`${AIRTABLE_BASE_URL}/${table}/${recordId}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });
  } catch (error) {
    console.error(`Error al eliminar el registro con ID ${recordId} en la tabla ${table}:`, error);
    throw error;
  }
};
 
/**
 * Función para obtener órdenes que cumplan con las condiciones para ser eliminadas.
 * @returns Promise<any[]>
 */
export const fetchOrdersToDelete = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Orders`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    const now = new Date();
    const ordersToDelete = response.data.records.filter((order: any) => {
      const orderDate = new Date(order.fields["Order Date"]);
      const diffMinutes = (now.getTime() - orderDate.getTime()) / (1000 * 60);
      return (
        diffMinutes > 5 &&
        order.fields["Order Status"] === "Orden pendiente" &&
        order.fields["Order Status Pagadito"] === "SENT"
      );
    });

    return ordersToDelete;
  } catch (error) {
    console.error("Error al obtener órdenes para limpiar:", error);
    throw error;
  }
};

/**
 * Función para eliminar una orden y su usuario asociado.
 * @param orderId - ID de la orden a eliminar.
 * @param userId - ID del usuario asociado.
 * @returns Promise<void>
 */
export const deleteOrderAndUser = async (orderId: string, userId: string): Promise<void> => {
  try {
    await deleteRecordFromAirtable("Orders", orderId); // Eliminar la orden
    await deleteRecordFromAirtable("Users", userId); // Eliminar el usuario asociado
  } catch (error) {
    console.error("Error al eliminar orden o usuario:", error);
    throw error;
  }
};

/**
 * Función para procesar la limpieza de órdenes antiguas.
 * @returns Promise<void>
 */
export const processOrderCleanup = async (): Promise<void> => {
  try {
    const ordersToDelete = await fetchOrdersToDelete();

    for (const order of ordersToDelete) {
      const orderId = order.id;
      const userIdArray = order.fields["Customer"];

      if (userIdArray && userIdArray.length > 0) {
        const userId = userIdArray[0];
        await deleteOrderAndUser(orderId, userId);
      } else {
        console.warn(`La orden ${orderId} no tiene un usuario asociado.`);
      }
    }
  } catch (error) {
    console.error("Error durante la limpieza de órdenes:", error);
  }
};

/**
 * Función para verificar si una orden existe.
 * @param customOrderId - ID sin decodificar de la orden a verificar.
 * @returns Promise<boolean> - Devuelve true si la orden existe, false en caso contrario.
 */
export const checkOrderByCustomId = async (customOrderId: string): Promise<any | null> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Orders`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
      params: {
        filterByFormula: `{ID} = "${customOrderId}"`,
      },
    });

    if (response.data.records && response.data.records.length > 0) {
      const order = response.data.records[0];
      return order;
    }

    console.warn("No se encontró ninguna orden con el ID personalizado:", customOrderId);
  } catch (error: any) {
    console.error(`Error al verificar la orden con ID personalizado ${customOrderId}:`, error);
    throw error;
  }
};

/**
 * Función para reducir el stock del producto asociado a una orden.
 * @param order - Orden a verificar.
 * @returns Promise<boolean> - Devuelve true si la orden existe, false en caso contrario.
 */
export const reduceProductStock = async (order: any): Promise<boolean> => {
  try {
    const fields = order.fields;
    const productId = fields['Product ID'][0];
    const quantity = fields['Quantity'];

    if (!productId || !quantity) {
      console.error('Orden inválida: falta Product ID o Quantity.');
      return false;
    }

    const product = await fetchProductById(productId);

    if (!product) {
      console.error(`No se encontró el producto con ID ${productId}`);
      return false;
    }
    
    const currentStock = parseInt(product.fields.Stock, 10);

    if (currentStock < quantity) {
      throw new Error('Stock insuficiente.');
    }

    const newStock = currentStock - quantity;

    await axios.patch(
      `${AIRTABLE_BASE_URL}/Products/${productId}`,
      { fields: { Stock: newStock } },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return true;
  } catch (error) {
    console.error('Error al reducir el stock del producto:', error);
    throw error;
  }
};

export const increaseProductStock = async (order: any): Promise<boolean> => {
  try {
    const fields = order.fields;
    const productId = fields['Product ID'][0];
    const quantity = fields['Quantity'];

    if (!productId || !quantity) {
      console.error('Orden inválida: falta Product ID o Quantity.');
      return false;
    }

    const product = await fetchProductById(productId);

    if (!product) {
      console.error(`No se encontró el producto con ID ${productId}`);
      return false;
    }

    const currentStock = parseInt(product.fields.Stock, 10);

    if (isNaN(currentStock)) {
      throw new Error(`Stock actual inválido para el producto con ID ${productId}.`);
    }

    const newStock = currentStock + quantity;

    await axios.patch(
      `${AIRTABLE_BASE_URL}/Products/${productId}`,
      { fields: { Stock: newStock } },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return true;
  } catch (error) {
    console.error('Error al aumentar el stock del producto:', error);
    throw error;
  }
};

/**
 * Función para actualizar los status de la orden.
 * @param order - Orden a verificar.
 * @param status - Estado de la orden.
 * @param pagaditoStatus - Estado de pagadito de la orden.
 * @returns Promise<boolean> - Devuelve true si la orden existe, false en caso contrario.
 */
export const updateOrderStatus = async (order: any, status: string, pagaditoStatus: string): Promise<boolean> => {
  try {
    if (!order || !order.id) {
      console.error('Orden inválida o no contiene un ID.');
      return false;
    }

    const airtableRecordId = order.id;

    await axios.patch(
      `${AIRTABLE_BASE_URL}/Orders/${airtableRecordId}`,
      {
        fields: {
          'Order Status': status,
          'Order Status Pagadito': pagaditoStatus,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return true;
  } catch (error) {
    console.error(`Error al actualizar el estado de la orden ${order.fields['ID']}:`, error);
    return false;
  }
};

/**
 * Función para obtener los registros de seguimiento (Tracking) de una orden específica.
 * 
 * @param orderId - El ID de la orden para la cual se quieren obtener los registros de seguimiento.
 * @returns Promise<any[]> - Una promesa que devuelve un arreglo de registros de seguimiento con el estado, la fecha de actualización y los comentarios.
 * 
 * @throws Error - Si ocurre algún error al obtener los datos de las tablas `Orders` o `Tracking`.
 */
export const fetchTrackingRecords = async (orderId: string): Promise<any[]> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    const orderIDValue = response.data.fields["ID"];

    if (!orderIDValue) {
      throw new Error("El campo 'ID' no se encontró en el registro.");
    }

    const trackingResponse = await axios.get(`${AIRTABLE_BASE_URL}/Tracking`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
      params: {
        filterByFormula: `{Order ID} = "${orderIDValue}"`,
      },
    });

    return trackingResponse.data.records.map((record: any) => ({
      id: record.id,
      fields: {
        status: record.fields.Status || "Sin estado",
        updateDate: record.fields["Date of update status"] || null,
        comments: record.fields.Comments || "",
      },
    }));
  } catch (error) {
    console.error("Error al obtener datos de la tabla Tracking:", error);
    throw error;
  }
};
