import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Thanks from '../Thanks';
import Error from '../Error';
import LottieAnimation from '../../../components/shared/LottieAnimation';
import { checkOrderByCustomId, increaseProductStock, reduceProductStock, updateOrderStatus } from '../../../services/airtableService';

const PagaditoReturn: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [orderData, setOrderData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

   const verifyPaymentStatus = async () => {

    const orderIdByParam = searchParams.get('ern_value');
    setOrderId(orderIdByParam);

    if (!orderIdByParam) {
      setStatus('error');
      console.error('No se pudo obtener el id de la orden.');
      setLoading(false);
      return;
    }

    const order = await checkOrderByCustomId(orderIdByParam);
    setOrderData(order);

    if (!order) {
      setStatus('error');
      console.error('No existe la orden.');
      setLoading(false);
      return;
    }

    const orderFields = order.fields;
    const isOrderPending = orderFields['Order Status'] === 'Orden pendiente';
    const isPagaditoSent = orderFields['Order Status Pagadito'] === 'SENT';

    if (!isOrderPending || !isPagaditoSent) {
      setStatus('error');
      console.error(`Order Status: ${orderFields['Order Status']}, Pagadito Status: ${orderFields['Order Status Pagadito']}`);
      setLoading(false);
      return;
    }

    const tokenResponse = await fetch('/api/initiate-payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ operation: 'connect' }),
    });

    if (!tokenResponse.ok) {
      setStatus('error');
      console.error('Error al obtener el token de Pagadito.');
      setLoading(false);
      return;
    }

    const { data } = await tokenResponse.json();
    const token = data.value;
    const token_trans = searchParams.get('token');

    if (!token || !token_trans) {
      setStatus('error');
      console.error('No se pudo obtener el token de Pagadito.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('/api/get-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          token_trans,
        }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error en la Api:', errorDetails);
        setStatus('error');
        return;
      }

      const data = await response.json();

      switch (data.status) {
        case 'COMPLETED':
        case 'REGISTERED':
        case 'VERIFYING':
          const stockUpdated = await reduceProductStock(order);
          if (stockUpdated) {
            const statusUpdated = await updateOrderStatus(order, 'Recibido', data.status);
            if (statusUpdated) {

              const orderDetails = {
                ID: orderFields.ID,
                "Full Name (from Customer)": orderFields["Full Name (from Customer)"]?.[0],
                "Shipping Adress (from Customer)": orderFields["Shipping Adress (from Customer)"]?.[0],
                Email: orderFields["Email Adress (from Customer)"]?.[0],
                "Name (from Product ID)": orderFields["Name (from Product ID)"]?.[0],
                Quantity: orderFields.Quantity,
                Subtotal: orderFields.Subtotal,
                Shipping: orderFields.Shipping,
                Total: orderFields.Total,
              };

              try {
                const response = await fetch('/api/send-email', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ orderDetails }),
                });
        
                if (!response.ok) {
                  console.error('Error al enviar el correo de confirmación:', await response.text());
                }
        
                setStatus('success');
              } catch (error) {
                console.error('Error al enviar el correo:', error);
                setStatus('error');
              }

            } else {
              console.error('Error al actualizar el estatus de la orden. Realizando rollback...');
              await increaseProductStock(order);
              setStatus('error');
            }
          } else {
            console.error('Error al reducir el stock del producto.');
            setStatus('error');
          }
          break;
        default:
          setStatus('error');
          console.error('Estado de transacción:', data.status);
          break;
      }
    } catch (error) {
      console.error('Error al verificar el estado:', error);
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyPaymentStatus();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center text-center p-6 py-16">
        <div className="-mt-10 w-full max-w-lg relative z-10">
          <LottieAnimation type="loading-status" loop={true} />
        </div>
        
        <h2 className="text-3xl font-bold text-[#EB773C] mb-4 -mt-10">
          Estamos verificando el estado de tu compra...
        </h2>
        <p className="text-gray-600 text-lg max-w-md">
          Por favor, espera unos segundos mientras confirmamos tu transacción con Pagadito.
        </p>
  
      </div>
    );  
  }
  return status === 'success' ? <Thanks orderId={orderId} /> : <Error orderData={orderData} />;
  
};

export default PagaditoReturn;
