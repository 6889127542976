import React, { useEffect, useState } from "react";
import {
  FaClipboardCheck,
  FaTruck,
  FaBoxOpen,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { fetchTrackingRecords } from "../../../services/airtableService";

interface ShipmentTrackingProps {
  orderData: any;
}

const iconMapping: Record<string, JSX.Element> = {
  "Recibido": <FaClipboardCheck />,
  "Pedido confirmado": <FaCheckCircle />,
  "Pedido rechazado": <FaTimesCircle />,
  "Listo para envío": <FaBoxOpen />,
  "En camino": <FaTruck />,
  "Entregado": <FaCheckCircle />,
};

const statusStyles: Record<string, string> = {
  "Recibido": "bg-blue-100 text-blue-600",
  "Pedido confirmado": "bg-green-200 text-green-700",
  "Pedido rechazado": "bg-red-100 text-red-600",
  "Listo para envío": "bg-blue-100 text-blue-600",
  "En camino": "bg-teal-100 text-teal-600",
  "Entregado": "bg-green-500 text-white",
};

const format12HourTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const ShipmentTracking: React.FC<ShipmentTrackingProps> = ({ orderData }) => {
  const [trackingHistory, setTrackingHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const orderId = orderData?.id;

  useEffect(() => {
    const loadTrackingData = async () => {
      if (!orderId) {
        console.error("No se proporcionó un ID de orden válido.");
        setLoading(false);
        return;
      }

      try {
        const records = await fetchTrackingRecords(orderId);
        const sortedRecords = records.sort((a, b) => 
          new Date(a.fields.updateDate).getTime() - new Date(b.fields.updateDate).getTime()
        );
        setTrackingHistory(sortedRecords);
      } catch (error) {
        console.error("Error al cargar el historial de tracking:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTrackingData();
  }, [orderId]);

  if (!orderData) {
    return (
      <div className="text-center">
        <p className="text-gray-600">No se encontró información para esta orden.</p>
      </div>
    );
  }

  if (loading) {
    return <p className="text-center">Cargando historial...</p>;
  }

  const { fields } = orderData;
  const product = fields["Product ID"]?.[0];

  return (
    <div className="container mx-auto px-8 md:px-0 my-10">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Order History */}
        <div className="lg:w-1/3 bg-gray-50 text-black rounded-lg shadow p-6">
          <h3 className="text-2xl font-bold mb-4">Historial del pedido</h3>
          {loading ? (
            <p className="text-center text-gray-500">Creando el historial del pedido...</p>
          ) : trackingHistory.length > 0 ? (
            <ul className="space-y-6 relative border-l-2 border-gray-300 pb-0 pl-6 ml-4">
              {trackingHistory.map((record, index) => (
                <li key={record.id} className="relative flex gap-4 items-start">
                  <div
                    className={`absolute -left-11 top-5 -translate-y-1/2 w-10 h-10 border rounded-full flex items-center justify-center shadow-sm ${
                      statusStyles[record.fields.status] || "bg-gray-100 text-gray-600"
                    }`}
                  >
                    {iconMapping[record.fields.status] || <FaClipboardCheck />}
                  </div>
                  <div className="ml-2">
                    <p className="text-sm font-medium">
                      {new Date(record.fields.updateDate).toLocaleDateString()}{" "}
                      {format12HourTime(record.fields.updateDate)}
                    </p>
                    <p className="text-sm text-gray-400">{record.fields.status}</p>
                    {record.fields.comments && (
                      <p className="text-sm text-gray-600 italic">"{record.fields.comments}"</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-500">No hay historial disponible para esta orden.</p>
          )}
        </div>

        {/* Product List */}
        <div className="lg:w-2/3 bg-gray-50 rounded-lg shadow p-6 sm:p-8 h-full">
          <h3 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center md:text-left">
            Seguimiento de la entrega del pedido{" "}
            <span className="text-[#EB773C]">#{fields["ID"]}</span>
          </h3>
          <ul className="space-y-4 sm:space-y-6">
            {[
              {
                name: fields["Name (from Product ID)"] || "Producto no identificado",
                id: product || "ID no disponible",
                customer: fields["Full Name (from Customer)"] || "N/A",
                city: fields["City (from Customer)"] || "N/A",
                shippingAddress: fields["Shipping Adress (from Customer)"] || "N/A",
                quantity: fields.Quantity || 0,
                price: fields.Subtotal || 0,
                imageUrl:
                  fields["Image (from Product ID)"]?.[0]?.url ||
                  "https://via.placeholder.com/80",
              },
            ].map((item, index) => (
              <li
                key={index}
                className="flex flex-col sm:flex-row items-start sm:items-center justify-between border-b pb-4 last:border-b-0 gap-4"
              >
                <div className="flex items-center gap-4">
                  <div className="relative">
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      className="w-20 h-20 object-cover rounded-lg shadow-sm"
                    />
                    {/* Div circular para la cantidad */}
                    <div className="sm:hidden absolute -top-2 -right-2 bg-red-500 text-white text-xs sm:text-sm font-bold rounded-full w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center">
                      {item.quantity}
                    </div>
                  </div>
                  <div>
                    <p className="font-medium text-sm sm:text-lg">{item.name}</p>
                    <p className="text-xs sm:text-sm text-gray-500">ID del producto: {item.id}</p>
                    <p className="text-xs sm:text-sm text-gray-500">Comprador(a): {item.customer}</p>
                    <p className="text-xs sm:text-sm text-gray-500">
                      Dirección de envío: {item.city}, {item.shippingAddress}
                    </p>
                  </div>
                </div>
                <div className="text-right hidden sm:block">
                  <p className="font-semibold text-lg text-gray-800">
                    C${item.price.toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-500">x{item.quantity}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-6 sm:mt-8 border-t pt-4 sm:pt-6 space-y-4">
            <div className="flex justify-between text-sm sm:text-base">
              <p className="text-gray-600">Subtotal</p>
              <p className="font-medium text-gray-800">C${fields.Subtotal.toFixed(2)}</p>
            </div>
            <div className="flex justify-between text-sm sm:text-base">
              <p className="text-gray-600">Envío estimado</p>
              <p className="font-medium text-gray-800">C${fields.Shipping.toFixed(2)}</p>
            </div>
            <div className="flex justify-between text-lg sm:text-xl font-bold mt-4 sm:mt-6">
              <p className="text-gray-800">Total</p>
              <p className="text-[#EB773C]">C${fields.Total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentTracking;
