import React from "react";
import Lottie from "lottie-react";
import animation404 from "../../../assets/images/404-cat.json";
import animationNotFound from "../../../assets/images/not-found.json";
import animationThanks from "../../../assets/images/thanks.json";
import animationError from "../../../assets/images/error.json";
import loadingDiscount from "../../../assets/images/loading-discount.json";
import message from "../../../assets/images/message.json";
import loadingProductDetail from "../../../assets/images/loading-product-detail.json"
import loadingStatus from "../../../assets/images/loading-status.json"

interface LottieAnimationProps {
  type: "404" | "not-found" | "thanks" | "error" | "loading-discount" | "message" | "loading-product-detail" | "loading-status";
  loop?: boolean;
  className?: string;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  type,
  loop = true,
  className = "",
}) => {
  const animations = {
    "404": animation404,
    "not-found": animationNotFound,
    "thanks": animationThanks,
    "error": animationError,
    "loading-discount": loadingDiscount,
    "message": message,
    "loading-product-detail": loadingProductDetail,
    "loading-status": loadingStatus,
  };

  const animationData = animations[type];

  return (
    <div className={`relative w-full h-auto ${className}`}>
      <Lottie animationData={animationData} loop={loop} />
    </div>
  );
};

export default LottieAnimation;
