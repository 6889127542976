import React, { useEffect } from 'react';
import Heading from '../../components/shared/Heading/Heading';
import pattern from '../../assets/images/background.svg';

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="free-bird">
      <div
        className="bg-[#808BC3] edge-header mb-4"
        style={{
          backgroundColor: '#808BC3',
          backgroundImage: `url(${pattern})`,
          backgroundSize: '1000px',
          backgroundRepeat: 'repeat',
        }}
      ></div>
      <div className="container free-bird mb-20 px-8 md:px-0">
        <div className="row">
          <div className="bg-gray-50 p-8 md:p-12 md:px-20 rounded-lg shadow-lg">
            <Heading className="font-bold text-[#EB773C] mb-6" isCenter isMain>
              Política de Privacidad
            </Heading>
            <div className="text-gray-700 text-base md:text-lg leading-relaxed space-y-6">
              <p>
                En Priscomni, nos comprometemos a proteger la privacidad de nuestros usuarios y garantizar la seguridad de la información personal que recopilamos a través de nuestro sitio web <a href="https://www.priscomni.com/" className="text-[#EB773C] underline">https://www.priscomni.com/</a>. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos su información.
              </p>
              <p className="font-bold">1. Información que Recopilamos:</p>
              <ul className="list-disc list-inside">
                <li><span className="font-semibold">Información personal proporcionada por el usuario:</span> Nombre completo, dirección de correo electrónico, número de teléfono, dirección de entrega.</li>
                <li><span className="font-semibold">Información recopilada automáticamente:</span> Dirección IP, tipo de dispositivo y navegador, datos de cookies.</li>
              </ul>

              <p className="font-bold">2. Cómo Utilizamos su Información:</p>
              <ul className="list-disc list-inside">
                <li><span className="font-semibold">Provisión de servicios:</span> Procesar pedidos y pagos, ofrecer soporte al cliente.</li>
                <li><span className="font-semibold">Comunicación:</span> Responder consultas y solicitudes, enviar actualizaciones sobre productos y promociones.</li>
                <li><span className="font-semibold">Mejora del sitio web:</span> Analizar el comportamiento de los usuarios para optimizar la experiencia, detectar y prevenir actividades fraudulentas.</li>
              </ul>

              <p className="font-bold">3. Compartición de Información:</p>
              <p>No vendemos, alquilamos ni compartimos su información personal con terceros, excepto en los siguientes casos:</p>
              <ul className="list-disc list-inside">
                <li><span className="font-semibold">Proveedores de servicios externos:</span> Compartimos información con proveedores como procesadores de pagos o servicios de envío, por ejemplo, Pagadito.</li>
                <li><span className="font-semibold">Cumplimiento legal:</span> Podemos divulgar información cuando sea necesario para cumplir con la ley o proteger nuestros derechos legales.</li>
                <li><span className="font-semibold">Con consentimiento:</span> Compartiremos información con terceros cuando el usuario lo autorice expresamente.</li>
              </ul>

              <p className="font-bold">4. Seguridad de la Información:</p>
              <p>Implementamos medidas técnicas y organizativas para proteger su información contra accesos no autorizados, pérdidas o alteraciones. Sin embargo, ningún método de transmisión de datos por internet es completamente seguro.</p>

              <p className="font-bold">5. Cookies y Tecnologías Similares:</p>
              <p>Utilizamos cookies y tecnologías similares para recordar sus preferencias y analizar el tráfico del sitio web.</p>

              <p className="font-bold">6. Enlaces a Sitios de Terceros:</p>
              <p>Nuestro sitio puede contener enlaces a sitios web de terceros. No somos responsables de sus prácticas de privacidad y recomendamos leer sus políticas antes de proporcionar información personal.</p>

              <p className="font-bold">7. Cambios en esta Política de Privacidad:</p>
              <p>Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Notificaremos cambios significativos publicando una versión actualizada en nuestro sitio web.</p>

              <p className="font-bold">8. Contacto:</p>
              <p>Si tiene preguntas o inquietudes sobre esta Política de Privacidad, puede contactarnos en: <a href="mailto:priscomshop@gmail.com" className="text-[#EB773C] underline">priscomshop@gmail.com</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
