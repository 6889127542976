import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import LottieAnimation from '../../components/shared/LottieAnimation';
import { fetchProductById, resolveCategory } from '../../services/airtableService';
import iProduct from '../../components/shared/Products/Products.props';
import noImage from '../../assets/images/no-image.jpg';

const ProductDetail: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();

  const [product, setProduct] = useState<any>(null);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const loadProduct = useCallback(async () => {
    if (!productId) return;

    try {
      setLoading(true);
      const productData = await fetchProductById(productId);

      if (productData) {
        const resolvedCategory = productData.fields.Category
          ? await resolveCategory(productData.fields.Category)
          : { id: "", name: "Sin categoría" };

        const formattedProduct: iProduct = {
          id: productData.id,
          name: productData.fields.Name,
          description: productData.fields.Description,
          price: productData.fields.Price,
          discountPrice: productData.fields.DiscountPrice,
          image: Array.isArray(productData.fields.Image) && productData.fields.Image.length > 0
            ? productData.fields.Image.map((img) => ({ url: img.url }))
            : [{ url: noImage }],
          category: resolvedCategory,
          stock: productData.fields.Stock,
          color: Array.isArray(productData.fields.Color) ? productData.fields.Color : [],
          size: Array.isArray(productData.fields.Size) ? productData.fields.Size : [],
          isOffert: productData.fields.IsOffert,
          handcraft: productData.fields.Handcraft,
        };

        setProduct(formattedProduct);
      } else {
        setProduct(null);
      }
    } catch (error) {
      console.error("Error al cargar el producto:", error);
      setProduct(null);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadProduct();
  }, [loadProduct]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center text-center p-6 py-24">
        <div className="w-full max-w-[25rem] h-auto">
          <LottieAnimation type={'loading-product-detail'} loop />
        </div>

        <h2 className="text-3xl font-bold mt-8 text-[#EB773C]">Cargando productos</h2>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex flex-col items-center justify-center text-center p-6 py-24">
        <div className='-mt-28 max-w-lg relative z-10'>
          <LottieAnimation type={'not-found'} loop={false} />
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Producto no encontrado</h2>
        <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
          Lo sentimos, no pudimos encontrar el producto que estás buscando.
        </p>
        <button
          onClick={() => navigate('/')}
          className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105">
          Inicio
        </button>
      </div>
    );
  }

  const handleIncrement = (stock: string) => {
    setQuantity((prev) => Math.min(prev + 1, Number(stock)));
  };
  const handleDecrement = () => setQuantity((prev) => Math.max(prev - 1, 1));

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const handleBuyNow = () => {
    if (!product) return;

    if (product.size?.length && !selectedSize) {
      setAlertMessage('Por favor selecciona un tamaño.');
      return;
    }

    if (product.color?.length && !selectedColor) {
      setAlertMessage('Por favor selecciona un color.');
      return;
    }

    navigate('/checkout', {
      state: {
        product,
        selectedSize: selectedSize || null,
        selectedColor: selectedColor || null,
        quantity,
      },
    });
  };

  return (
    <div className="container mx-auto px-8">
      <div className="mb-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-white border border-gray-300 p-2 rounded-full shadow hover:bg-gray-100 transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-gray-800">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
      </div>
      { product && (
        <div className="flex flex-col lg:flex-row items-start gap-10">
          <div className="flex-1">
            {product.image?.length > 1 ? (
              <Splide
                options={{
                  type: 'loop',
                  perPage: 1,
                  pagination: true,
                  arrows: true,
                }}
                className="custom-splide -mb-20 md:-mb-[8.9rem] lg:-mb-0">
                {product.image.map((image: { url: string }, index: number) => (
                  <SplideSlide key={index}>
                    <img
                      src={image.url}
                      alt={`Imagen ${index + 1} de ${product.name}`}
                      loading="lazy"
                      className="w-full h-5/6 rounded object-cover object-center "
                    />
                  </SplideSlide>
                ))}
              </Splide>
            ) : (
              product.image?.[0]?.url && (
                <img
                  src={product.image[0].url}
                  alt={`Imagen de ${product.name}`}
                  loading="lazy"
                  className="w-full h-5/6 rounded object-cover object-center"
                />
              )
            )}
          </div>

          <div className="flex-1">
            {product.isOffert && Number(product.discountPrice) > 0 ? (
              <>
                <div className="flex flex-col items-start gap-2 mb-4">
                  <h1 className="text-3xl font-bold text-gray-800">{product.name}</h1>
                  <div className="flex flex-wrap gap-2 ">
                    {product.isOffert && Number(product.discountPrice) > 0 && (
                      <div className="h-auto mt-1 px-3 py-1 bg-[#808BC3] text-white text-sm font-semibold rounded shadow">
                        Promoción
                      </div>
                    )}
                    {product.handcraft && (
                      <div className="h-auto mt-1 px-3 py-1 bg-[#808BC3] text-white text-sm font-semibold rounded shadow">
                        Hecho a mano
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                {product.isOffert && (
                  <div className='mb-6'>
                    <span className="text-xl font-semibold text-[#EB773C]">
                      C${Number(product.discountPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                    <span className="text-lg text-gray-500 line-through ml-2">
                      C${Number(product.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </div>
                )}
                </div>
              </>
            ) : (
              <div className='mb-6'>
                <h1 className="text-3xl font-bold text-gray-800 mb-4">{product.name}</h1>
                <span className="text-xl font-semibold text-gray-700 mb-6">
                  C${Number(product.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
              </div>
            )}

            {product?.description && (
              <p className="text-gray-600 mb-6">{product.description}</p>
            )}

            {product?.size?.length > 0 && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tamaños disponibles:</h3>
                <div className="flex gap-2">
                  {product.size?.map((size: string, index: number) => (
                    <button
                      key={size}
                      onClick={() => setSelectedSize(size)}
                      className={`px-4 py-2 border rounded ${
                        selectedSize === size
                          ? 'bg-[#EB773C] text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}>
                      {size}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {product?.color?.length > 0 && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Colores disponibles:</h3>
                <div className="flex gap-3">
                  {product.color?.map((color: string, index: number) => (
                    <button
                      key={color}
                      onClick={() => setSelectedColor(color)}
                      className={`px-4 py-2 border rounded ${
                        selectedColor === color
                          ? 'bg-[#EB773C] text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}>
                      {color}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <h3 className="text-lg font-semibold text-gray-800 mb-2">Stock:</h3>
            <div className="flex gap-3">
              <p className="text-gray-600 mb-6">{product.stock}</p>
            </div>

            {Number(product.stock) > 0 && (
              <>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Cantidad:</h3>
                <div className="mb-6 flex items-center justify-center w-28 border border-gray-300 rounded-lg">
                  <button
                    onClick={handleDecrement}
                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-black">
                    -
                  </button>
                  <span className="w-8 h-8 flex items-center justify-center text-gray-800 font-medium">
                    {quantity}
                  </span>
                  <button
                    onClick={() => handleIncrement(product.stock)}
                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-black">
                    +
                  </button>
                </div>
              </>
            )}

            {product?.handcraft ? (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tiempo de entrega:</h3>
                <div className="flex flex-col gap-3">
                  <p className="text-gray-600 mb-6 w-full max-w-screen-md break-words">
                    Nuestros productos son elaborados 100% a mano, con amor y dedicación. Es por eso, el tiempo de entrega puede tardar hasta 15 días hábiles luego de la compra ¡pero te garantizamos que valdrá la pena!
                  </p>
                </div>
              </div>
            ) : (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tiempo de entrega:</h3>
                <div className="flex flex-col gap-3">
                  <p className="text-gray-600 mb-6 w-full max-w-screen-md break-words">
                    ¡Recibe tus productos favoritos hasta la puerta de tu casa! El tiempo de entrega puede durar desde 5 hasta 24 horas. Recuerda que puedes verificar el estado de tu pedido en nuestro rastreador de producto.
                  </p>
                </div>
              </div>
            )}

            {alertMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <span className="w-96 sm:inline">{alertMessage}</span>
                  <button className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={closeAlert} >
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Cerrar</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                    </svg>
                  </button>
              </div>
            )}

            <button
              onClick={handleBuyNow}
              disabled={Number(product.stock) === 0 || Number(product.price) === 0 || !product.name}
              className={`w-full md:w-80 font-bold py-3 px-6 shadow rounded-lg transition ${
                Number(product.stock) === 0 || Number(product.price) === 0 || !product.name
                  ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                  : 'bg-[#EB773C] text-white hover:bg-[#d46834]'
              }`}>
              {Number(product.stock) === 0
                ? 'Sin stock disponible'
                : Number(product.price) === 0
                ? 'Producto no disponible'
                : !product.name
                ? 'Producto sin nombre'
                : 'Comprar este producto'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
