import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import { FiSearch } from 'react-icons/fi';
import ShipmentTracking from './ShipmentTracking';
import { checkOrderByCustomId } from '../../services/airtableService';

const Track: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchParams] = useSearchParams();
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [orderData, setOrderData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('Ingrese un número de rastreo para ver la información del pedido.');

  const fetchOrderByTrackingNumber = async (tracking: string) => {
    if (!tracking.trim()) {
      setMessage('Por favor, ingrese un número de rastreo válido.');
      return;
    }

    setLoading(true);
    setOrderData(null);

    try {
      const order = await checkOrderByCustomId(tracking.trim());
      if (order) {
        setOrderData(order);
        setMessage('');
      } else {
        setMessage('No se encontró ninguna orden con el número de rastreo proporcionado.');
      }
    } catch (error) {
      console.error('Error al buscar la orden:', error);
      setMessage('Ocurrió un error al buscar la orden. Por favor, inténtelo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    await fetchOrderByTrackingNumber(trackingNumber);
  };

  useEffect(() => {
    const trackingParam = searchParams.get('trackingNumber');
    if (trackingParam) {
      setTrackingNumber(trackingParam.toUpperCase());
      fetchOrderByTrackingNumber(trackingParam);
    }
  }, [searchParams]);

  return (
    <div className="container mb-20">
      <div className="mb-10">
        <HeaderSection />
      </div>
      <div className="px-8 md:px-0">
        <div className="bg-gray-50 shadow py-5 px-6 rounded-lg">
          <label htmlFor="trackNumber" className="text-sm font-medium text-gray-700">
            Número de rastreo
          </label>
          <div className="relative flex flex-col lg:flex-row items-center">
            <input
              id="trackNumber"
              type="text"
              minLength={5}
              placeholder="Ingrese el número de rastreo"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value.toUpperCase())}
              className="w-full border rounded-lg p-2 focus:ring-2 focus:ring-orange-400 focus:outline-none mt-2"
            />
            <button
              onClick={handleSearch}
              disabled={!trackingNumber.trim() || loading}
              className={`mt-4 lg:mt-2 lg:ml-4 flex items-center px-4 py-3 rounded-lg transition ${
                trackingNumber.trim() && !loading
                  ? 'bg-[#EB773C] text-white hover:bg-[#d46834]'
                  : 'bg-gray-300 text-gray-600 cursor-not-allowed'
              }`}
            >
              {loading ? 'Buscando...' : <><FiSearch className="mr-2 text-xl" />Buscar</>}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-10">
        {orderData ? (
          <ShipmentTracking orderData={orderData} />
        ) : (
          <p className="text-center text-gray-500 px-8 md:px-0">{message}</p>
        )}
      </div>
    </div>
  );
};

export default Track;
