import { useNavigate } from "react-router-dom";
import LottieAnimation from "../../../components/shared/LottieAnimation";
import { useEffect } from "react";

interface ThanksProps {
  orderId?: string | null;
}

const Thanks: React.FC<ThanksProps> = ({ orderId }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleTrack = () => {
    if (orderId) {
      navigate(`/track?trackingNumber=${orderId}`);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center p-6 py-24">
      <div className="-mt-10 w-full max-w-lg relative z-10">
        <LottieAnimation type="thanks" loop={false} />
      </div>
      
      <h2 className="text-3xl font-bold text-gray-800 mb-4 -mt-10">
        ¡Gracias por tu compra!
      </h2>

      <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
        Hemos recibido tu pedido con éxito. Recibirás una confirmación en tu correo. <br /> 
        ¡Gracias por confiar en nosotros! 
        <br />
        {orderId && (
          <>Orden No: {orderId}</>
        )}
      </p>

      <button
        onClick={handleTrack}
        className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105"
      >
        Rastrear Pedido
      </button>
    </div>
  );
};

export default Thanks;