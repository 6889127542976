import React from 'react';
import { MdCopyright } from 'react-icons/md';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className=" bg-[#262626] text-white">
      <div className="flex flex-col items-center text-center gap-4 px-4 py-5 md:flex-row md:justify-around md:text-left">
        <div className="flex items-center gap-1 text-sm md:text-base">
          <MdCopyright /> <span>{currentYear} Priscom. Todos los derechos reservados</span>
        </div>

        <div className="flex flex-col gap-2 md:flex-row md:gap-5">
          <a href="/terminos" className="hover:underline">
            Términos y Condiciones
          </a>
          <a href="/privacidad" className="hover:underline">
            Política de privacidad
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
