import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getLinkClass = (path: string) => {
    return currentPath === path
      ? 'text-[#EB773C] border-b-2 border-[#EB773C]'
      : 'hover:text-[#EB773C]';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="shadow-sm">
      <div className="container mx-auto flex items-center justify-between py-1">
        <div className="flex-1 ps-5">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-auto w-[180px]" />
          </Link>
        </div>

        <div className="hidden lg:flex items-center gap-7 text-black font-medium">
          <Link to="/" className={getLinkClass('/')}>Inicio</Link>
          <Link to="/store" className={getLinkClass('/store')}>Tienda</Link>
          <Link to="/track" className={getLinkClass('/track')}>Rastrear Pedido</Link>
        </div>

        <div className="hidden flex-1 lg:flex" />

        <div className="lg:hidden pe-5">
          <button
            type="button"
            onClick={toggleMenu}
            className="flex items-center justify-center rounded-lg p-2.5 focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-white z-50 flex flex-col items-center justify-center text-center text-[#262626] font-medium transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out`}>
        <button
          onClick={toggleMenu}
          className="absolute top-6 right-6 text-3xl focus:outline-none">
          &times;
        </button>
        <img src={logo} alt="Logo" className="h-auto w-[150px] mb-10" />
        <Link
          to="/"
          className={`text-2xl mb-6 ${getLinkClass('/')}`}
          onClick={toggleMenu}>
          Inicio
        </Link>
        <Link
          to="/store"
          className={`text-2xl mb-6 ${getLinkClass('/store')}`}
          onClick={toggleMenu}>
          Tienda
        </Link>
        <Link
          to="/track"
          className={`text-2xl ${getLinkClass('/track')}`}
          onClick={toggleMenu}>
          Rastrear Pedido
        </Link>
      </div>
    </div>
  );
};

export default Header;
