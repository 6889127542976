import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Store from './pages/Store';
import Track from './pages/Track';
import NotFound from './pages/NotFound';
import ProductDetail from './pages/ProductDetail';
import Checkout from './pages/Checkout';
import Thanks from './pages/Checkout/Thanks';
import Error from './pages/Checkout/Error';
import TermsOfService from './pages/TermsOfService';
import PagaditoReturn from './pages/Checkout/PagaditoReturn';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <div className="sticky inset-x-0 top-0 z-50 bg-white">
          <Header />
        </div>
        <div className="flex-grow my-7">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/store" element={<Store />} />
            <Route path="/track" element={<Track />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/error" element={<Error />} />
            <Route path="/pagadito-return" element={<PagaditoReturn />} />
            <Route path="/terminos" element={<TermsOfService />} />
            <Route path="/privacidad" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
